




















import api from "@/api";
import { Component, Vue } from "vue-property-decorator";
import { AttachmentHostType, MessageDto } from "@/api/appService";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
@Component({
  name: "messageDetail",
  components: {
    ExportWord,
    AttachmentsView,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class MessageDetail extends Vue {
  dataId?: number;
  detail: MessageDto = {};
  typeList: any = [];
  styles = "";
  selector = "table-detail-world";
  filename = "基金消息详情";
  hostType = AttachmentHostType.Message;

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchEnumType();
      this.fetchDetail();
    }
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "MessageType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取详情
  async fetchDetail() {
    api.message.get({ id: this.dataId }).then((res) => {
      this.detail = { ...res };
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
